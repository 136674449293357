.articles-slider {
  background: #121212 !important;
  padding: 80px 0px;
}

.article-wrapper {
  padding: 10px;
  height: 100%;
  border-radius: 20px;
}

.settingss-div div {
  padding-right: 4px;
  padding-left: 4px;
}

.articles {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 350px;
}

.articles.title {
  font-size: 20px;
}

.articles .article-date {
  color: #d1d1d1;
  border-radius: 5px;
  background-color: #0000004f;
}

.articles .article-autor {
  color: #ff7700;
}

.articles .article-autor {
  color: #d1d1d1;
  border-radius: 5px;
  background-color: #0000004f;
}

.slick-list,
.slick-slider,
.slick-track {
  display: flex;
}
.guaranteed-reliable {
  position: relative;
  display: flex;
  z-index: 2;
  font-size: 48px;
  font-weight: bolder;
  background: -webkit-linear-gradient(60deg, #c5002e, #700074 35%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.articles-slider .slick-prev:before {
  font-size: 18px;
  color: #ff7700;
  background-color: transparent;
  font-weight: 800;
  transition: display 0.5s;
}

.articles-slider .slick-next:before {
  font-size: 18px;
  color: #ff7700;
  background-color: transparent;
  font-weight: 800;
  transition: visibility 0.5s;
}

@media only screen and (max-width: 1110px) {
  .articles-slider {
    display: none;
  }
}
