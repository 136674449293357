.phone-section{
    background-color: #202020;
}

.page-title{
    font-size: 32px;
    color: #fff;
}

.sub-title{
    font-size: 16px;
    color: #fff;
}

.service-button{
    margin-top: 10px;
    background-color: #fff;
    border-radius: 12px;
    width: 240px;
    height: 40px;
    border: 0;
    margin-bottom: 20px;
    transition: 0.5s;
    background: linear-gradient(180deg, rgba(229,26,75,1) 0%, rgba(94,11,31,0.9838060224089635) 100%);
}

.service-button:hover{
    background-color: #000;
}

.service-about-btn:hover{
    background-color: #343434;
}

.cat-title {
    padding-top: 10px;
    text-align: center;
    font-size: 28px;
    color: #fff;
    position: relative;
}

.brands-img img{
    width: 100%!important;
    filter: grayscale(1);
    transition: 0.3s;
    transform: scale(0.8);
}

.card-container-section .card-container:hover .brands-img img{
    filter: grayscale(0);
    transform: scale(0.9);
}

.cat-title:before,
.cat-title:after {
    content: '';
    position: absolute;
    top: 70%;
    width: calc(50% - 200px);
    border-top: 1px solid #707070;
}

.cat-title:before {
    left: 25px;
}

.cat-title:after {
    right: 25px;
}

.card-container-section{
    align-items: center;
    display: grid;
    gap:30px;
    margin-top: 20px;
    padding-bottom: 50px;
    grid-template-columns: auto auto auto auto;
}

.card-container{
    cursor: pointer;
    height: 100%;
    border-radius: 15px;
    text-align: center;
    background: #121212;
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    border: 1px solid #000
}


.card-container:hover{
    box-shadow: 0 10px 10px hwb(0 16% 77% / 0.265), 0 1px 2px rgba(63, 60, 60, 0.838);
}

.brands-container{
    background-color: #202020;
    padding-top: 10px;
}

@media only screen and (max-width: 1400px) {
    .phone-section {
      padding-left: 20px!important;
      padding-right: 20px!important;
    }
}

@media only screen and (max-width: 1200px) {
    .phone-section {
      padding-left: 10px!important;
      padding-right: 10px!important;
    }

    .brands-container{
        display: none;
    }

    .card-container-section{
        flex-wrap: wrap;
        justify-content: none;
        grid-template-columns: repeat(2,1fr);
        display: grid;
    }

    .service-button{
        background-color: #E51A4B;
        border-radius: 12px;
        width: 240px;
        height: 40px;
        margin-bottom: 20px;
    }

    .service-button-text{
        color: #fff;
        font-size: 14px;
    }


    .service-about-btn{
        width: 100%;
        margin-top: 20px;
    }

    .service-button{
        width: 100%;
    }
  }

@media only screen and (max-width: 610px) {
    .card-container-section{
       display: block;
    }
    
    .card-container{
        margin: 10px 0px;
    }
}