 .contents-container{
    background-color: #121212;
}

 .animationContainer img {
    -o-object-fit: contain;
    object-fit: contain;
    width: 456px;
    max-height: 456px;
}
.contain {
    display: flex;
    justify-content: space-evenly;
}
.animationContainer {
    display: flex;
    width: 50%;
    height: 456px;
    justify-content: center;
    align-items: flex-start;
}
  
  .star {
    width: 100%;
    animation: star 12s infinite; /* 3s for rotation, 3s for scaling */
  }
  
  @keyframes star {
    0%, 100% {
      transform: rotate(0deg) scale(1);
    }
    25% {
      transform: rotate(120deg) scale(0.6);
    }
    50% {
      transform: rotate(220deg) scale(0.9);
    }
    75% {
      transform: rotate(280deg) scale(0.8);
    }
    100% {
      transform: rotate(360deg) scale(1);
    }
  }
  
  .star {
    -webkit-animation: star 12s infinite ease; /* for WebKit browsers */
    animation: star 6s infinite ease;
  }

.buttonContainer {
    display: flex;
    flex-direction: column;
    width: 50%;
}
.buttonItem {
    font-size: 55px;
    color: #7b7b7b;
    font-weight: 700;
    cursor: pointer;
    margin-bottom: 20px;
    transition: 0.3s;
}
.selected {
  transition: 0.3s;
    color: white;
}


@media only screen and (max-width: 1110px) {
    .contents-container{
        display: none;
    }
}