.brand-container{
    background-color: black!important;
    padding-bottom: 210px;
    padding-top: 300px;
}

.brands-body{
    display: flex;
    width: 231px;
    height: 160px;
    background-color: #212121;
    border-radius: 39px;
    justify-content: center;
    align-items: center;
}

.containers-body{
    padding-bottom: 40px;
}

.brand-body1{
    padding-top: 80px;
}

.brands-body-div{
    display: flex;
    width: 231px;
    height: 160px;
    background-color: #212121;
    border-radius: 39px;
    justify-content: center;
    align-items: center;
    margin-right: 30px;
}

@media only screen and (max-width: 3000px) {
    .brand-container{
        background-color: black!important;
        padding-bottom: 210px;
    }
    
}

@media only screen and (max-width: 1300px) {
    .brand-container{
        display: none;
    }
}
