.sliderinfo {
  margin-left: 225px;
  padding-top: 100px;
}

.pricing-table-btn {
  background: linear-gradient(
    180deg,
    rgba(229, 26, 75, 1) 0%,
    rgba(122, 8, 74, 1) 100%
  );
  padding: 18px;
  border-radius: 19px;
  width: 430px;
  height: 55px;
  margin: 0px;
}

.quick-repair-title {
  line-height: 1.1 !important;
}

.pricing-table-text {
  color: white !important;
  z-index: 9999;
  text-decoration: none;
}

.quick-text {
  width: 40%;
}

.pricing-button button {
  border: 0;
}

@media only screen and (max-width: 1300px) {
  .sliderinfo {
    margin-left: 30px !important;
  }

  .repair-slider-section {
    background-color: black;
  }
}

@media only screen and (max-width: 992px) {
  .slider-info-section {
    background-position: inherit;
  }

  .quick-repair-title {
    font-size: 3.375rem;
    text-align: center;
  }

  .quick-text {
    width: 60%;
    text-align: center;
    margin: auto;
  }

  .pricing-button {
    text-align: center;
  }

  .pricing-table-btn {
    width: 60%;
  }
}

.pricing-table-btn {
  margin-top: 20px;
}

@media only screen and (max-width: 568px) {
  .pricing-table-btn {
    width: 100%;
    margin-top: 20px;
  }

  .quick-repair-title {
    font-size: 3.375rem;
    text-align: center;
  }

  .quick-text {
    width: 100%;
  }

  .slider-info-section {
    background-position: inherit;
  }

  .sliderinfo {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
}
