.blog-container {
  background-color: #121212 !important;
  padding-bottom: 100px;
}

.repair {
  background-color: #231030;
  padding-top: 40px;
  padding-bottom: 20px;
  border-radius: 35px;
  margin-top: 100px;
}

.repair-solution {
  line-height: 1.1;
}

.repair-img {
  padding-top: 30px;
}

.repair-img img {
  width: 100%;
  max-width: 400px;
}

.repair-warranty-body {
  margin-top: 100px;
}

.repair-warranty {
  display: flex;
  height: 348px;
}

.warranty-title {
  padding-top: 11%;
}

.warranty-img {
  width: 100%;
}

.warranty-img img {
  width: 100%;
  height: 100%;
  object-fit: none;
  position: relative;
  overflow: hidden;
  border-radius: 35px;
}

.warrantyy-img {
  padding-top: 30px;
  overflow: hidden;
  border-bottom-right-radius: 35px;
}

.warrantyy-img img {
  height: 100%;
  object-fit: cover;
  position: relative;
}

.repair-warranty-container {
  margin-top: 20px;
  background-color: #343434;
  border-radius: 35px;
  display: flex;
  height: 348px;
}

.repair-warranty {
  background: linear-gradient(
    180deg,
    rgba(14, 53, 80, 1) 0%,
    rgba(20, 118, 138, 1) 100%
  );
  border-radius: 35px;
}

@media (max-width: 1110px) {
  .quick-repair-body {
    display: block;
  }

  .repair {
    width: 100%;
    margin-top: 50px;
  }

  .repair-warranty-body {
    width: 100%;
    margin-top: 20px;
  }

  .information-container {
    display: none;
  }
  .repair-solution-title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 500px) {
  .blog-title {
    font-size: 3.375rem;
  }

  .quick-repair-body {
    width: 100%;
    margin: auto;
  }

  .quick-repair-div {
    width: 100%;
  }

  .scrooter-img img {
    width: 50% !important;
  }

  .quickrepair-title {
    font-size: 35px;
  }

  .repair-warranty {
    display: block;
    height: auto;
  }

  .repair-solution {
    font-size: 35px;
    text-align: center;
  }

  .repair-solution-text {
    width: 100%;
    padding-right: 1.25rem;
    text-align: center;
  }

  .repair-text {
    text-align: center;
    width: 100%;
  }

  .online-fault-title {
    font-size: 35px;
  }

  .repair-warranty-container {
    display: block;
    height: 100%;
  }

  .warranty-img {
    padding-top: 20px;
  }

  .repair-warranty-body {
    margin-top: 20px;
  }

  .container {
    justify-content: center;
  }

  .warranty-img img {
    width: 100%;
    height: 100%;
    /* object-fit: none; */
    position: relative;
    overflow: hidden;
    right: 20%;
    border-radius: 35px;
  }
}
