html {
  background-color: #000;
  overflow-x: hidden;
}

#root {
  background-color: #121212;
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
}
.scrolltop-btn:hover {
  background-color: gray;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #000000;
}

::-webkit-scrollbar {
  width: 5px;
  background-color: #000000;
}

::-webkit-scrollbar-thumb {
  border-radius: 2px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgb(229, 26, 75);
}

.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
}

.transform-none:active {
  transform: none;
}

button {
  border: 0;
}

button:active {
  transform: none !important;
}

.spinner {
  border: 4px solid rgba(203, 203, 203, 0.1);
  border-left: 4px solid #ffffff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

div:where(.swal2-container) div:where(.swal2-popup) {
  background: #232323 !important;
  color: #ffffff !important;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-cancel {
  background-color: #a00000 !important;
  color: #fff !important;
  font-weight: bold !important;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  background-color: #1e7c1d !important;
  color: #fff !important;
  font-weight: bold !important;
}
