.header {
  height: 60px;
  padding-left: var(--mantine-spacing-md);
  padding-right: var(--mantine-spacing-md);
  border-bottom: 1px solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
}

.header-container {
  background-color: #121212;
  height: 85px;
}

.header-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-container img {
  height: 85px;
}

.link {
  font-size: 14px !important;
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: var(--mantine-spacing-md);
  padding-right: var(--mantine-spacing-md);
  text-decoration: none;
  color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
  font-weight: 500;
  font-size: var(--mantine-font-size-sm);
}

.link:hover {
  background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-6));
}


.subLink {
  width: 100%;
  padding: var(--mantine-spacing-xs) var(--mantine-spacing-md);
  border-radius: var(--mantine-radius-md);
}

.subLink:hover {
  background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-7));
}

.dropdownFooter {
  background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-7));
  margin: calc(var(--mantine-spacing-md) * -1);
  margin-top: var(--mantine-spacing-sm);
  padding: var(--mantine-spacing-md) calc(var(--mantine-spacing-mfd) * 2);
  padding-bottom: var(--mantine-spacing-xl);
  border-top: 1px solid light-dark(var(--mantine-color-gray-1), var(--mantine-color-dark-5));
}

.nav-item img {
  padding-right: 8px;
}

.menu-nav-button-group {
  margin-top: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.mini-menu-margin {
  margin-left: 10px;
  margin-right: 10px;
}

.menu-nav-pricing {
  color: white;
  text-decoration: none;
}

.transactions-button-div {
  margin-left: 10px;
  margin-right: 30px;
  background: linear-gradient(180deg, rgba(229, 26, 75, 1) 0%, rgba(71, 0, 74, 1) 100%);
  border-radius: 10px;
  height: 42px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.transactions-button-div a {
  color: white;
  text-decoration: none;
}

.menu-nav-pricing {
  height: 42px;
  width: 200px;
  padding-left: 10px;
  padding-right: 10px;
  border: 2px solid rgba(229, 26, 74, 0.4);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-nav-pricing img {
  padding-right: 8px;
  height: 100% !important;
  padding-top: 10px;
  padding-bottom: 10px;
}

.nav-item-div a {
  color: white;
  text-decoration: none;
  margin-left: 8px;
}

.nav-item-div img {
  height: 100%;
  width: 18px;
  margin-bottom: 4px;
}

.category-title,
.services-title {
  position: relative;
  display: inline-block;
  padding-bottom: 2px;
  transition: padding-bottom 0.3s ease;
}

.category-title::before,
.services-title::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #e51a4b;
  bottom: 0;
  left: 0;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.category-title:hover::before,
.services-title:hover::before {
  transform-origin: left;
  transform: scaleX(1);
}

.phone-title {
  border-bottom: 3px solid red;
}

.category-div {
  display: flex;
  align-items: center;
  padding-top: 20px;
}

.services-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.services {
  display: flex;
  align-items: center;
  padding-top: 20px;
}

.services-div {
  margin-right: 60px;
}

.online-transactions {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.member-login-div {
  display: inline-block;
  padding-top: 25px;
  padding-left: 138px;
  width: 350px;
  height: 136px;
  background: #0087BA;
  border-radius: 30px;
}

.member-login-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 25px;
  padding-bottom: 40px;
}

.member-login-divv {
  display: inline-block;
  padding-top: 25px;
  padding-left: 138px;
  width: 350px;
  height: 136px;
  background: #61B500;
  border-radius: 30px;
}

.member-login-img {
  padding-left: 75px;
}

.member-login-divvv {
  display: inline-block;
  padding-top: 25px;
  padding-left: 50px;
  width: 350px;
  height: 136px;
  background: #ED7D19;
  border-radius: 30px;
}

.deltafixpro img {
  width: 100%;
  height: 100%;
}

.category-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.services-section {
  display: flex;
}

.services-about {
  margin-right: 50px;
}

.login-member {
  background: linear-gradient(90deg, rgba(164, 162, 163, 1) 0%, rgba(204, 203, 204, 1) 55%);
  border-radius: 10px;
}

.login-btn:hover {
  background-color: #121212;
}

@media only screen {
  .header-container {
    background-color: #121212;
  }

  .hello {
    display: none;
  }

  .mantine-zpoir7,
  .mantine-zpoir7::before,
  .mantine-zpoir7::after {
    display: block;
    width: 1.5rem;
    height: calc(1.5rem / 12);
    background-color: #f0ecec;
    outline: 0.0625rem solid transparent;
    transition-property: background-color, transform;
    transition-duration: 300ms;
  }
}

.header-menu-nav {
  display: inline-grid;
}


@media only screen and (max-width: 1110px) {
  .header-nav {
    display: none;
  }

  .hello {
    display: block;
  }

  .transactions-button-div,
  .menu-nav-pricing {
    width: 48%;
    max-width: 200px;
    margin-right: 10px;
  }

}

.mantine-8a5c2a {
  outline: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: white !important;
  background-color: #121212;
  box-sizing: borderrgb(29, 28, 28);
  border-radius: 20px;
  box-shadow: 0 0.0625rem 0.1875rem rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05) 0 2.25rem 1.75rem -0.4375rem, rgba(0, 0, 0, 0.04) 0 1.0625rem 1.0625rem -0.4375rem;
  pointer-events: all;
  -webkit-flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  border-radius: 0;
  overflow-y: auto;
}

.mantine-19pz3dh {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 1rem;
  padding-right: calc(1rem - 0.3125rem);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #121212;
  z-index: 1000;
}