.inNearContainer {
  background: linear-gradient(
    180deg,
    rgba(23, 70, 101, 1) 0%,
    rgba(236, 22, 81, 1) 100%
  );
  border-radius: 12px;
  height: 354px;
}

.innear-title {
  position: relative;
  top: -12px;
}

.delta-message-img {
  border-radius: 12px;
}

.phone-container {
  border-radius: 10px;
  margin-top: 10px;
  height: 350px;
}

.delta-message-img {
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
  height: 715px;
  max-height: 715px;
  padding: 15px 35px;
  background-color: #050505;
}

.delta-message-img img,
.delta-message-img video {
  display: block;
  padding: 20px;
  width: 100%;
  height: auto;
  max-height: 715px;
}

.delta-message-img video {
  position: absolute;
  margin-top: 100px;
  max-height: 540px;
  padding: 18px;
  top: 0;
  left: 0;
}

.map-ada-img iframe {
  border-radius: 12px;
  filter: invert(100%) grayscale(95%);
}

.map-ada-img img {
  margin-top: 10px;
  width: 100%;
  height: 350px;
  border-radius: 12px;
}

.phone-container {
  background-color: #232323;
}

.contactContainerInner {
  display: flex;
  align-items: center;
  justify-content: center;
  perspective: 5000px;
  width: 100%;
  height: 180px;
  overflow: hidden;
}

.contact-links {
  color: #d1d1d1;
  text-decoration: none;
  font-size: 16px;
}

.contactCard {
  opacity: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0px -40px;
  transform: rotateY(45deg);
  transform-origin: center center;
  transition: all 0.2s ease-in-out;
  background: linear-gradient(
    297deg,
    rgba(35, 35, 35, 1) 0%,
    rgba(78, 78, 78, 1) 100%
  );
  border-radius: 12px;
  width: 100%;
  height: 160px;
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;
}

.contactCard:hover {
  width: 100%;
  height: 100%;
  transform: rotateY(0deg);
  margin: 0px;
  left: 0;
}

.call-img {
  text-align: center;
}

@media only screen and (max-width: 1110px) {
  .where-grid {
    display: block;
  }

  .where-title {
    font-size: 3.375rem;
  }

  .where-body {
    max-width: 100%;
  }

  .delta-message-body {
    display: none;
  }

  .map-ada-body {
    max-width: 100%;
    margin-top: 20px;
  }

  .inNearContainer {
    margin-bottom: 20px;
  }

  .map-ada-img {
    margin-top: 20px;
  }
}
