.device-shipping-section {
  padding-top: 35px;
  padding-bottom: 35px;
  background: linear-gradient(
    180deg,
    #53091a 0%,
    #121212 10%,
    #121212 50%,
    #000 100%
  );
  background-color: #121212;
  border-top: solid 1px #e51a4b;
}

.device-con-body {
  background-color: #000;
}

.request-btn {
  background: linear-gradient(90deg, rgb(27, 161, 31) 8%, rgb(22, 180, 14) 56%);
}

.selected-device {
  width: 50%;
  filter: drop-shadow(15px 15px 10px #00000028);
}

.device-shipping-section .warning-box {
  text-align: left;
  color: #adb5bd;
  background-color: #000;
  border: 1px solid #ced4da;
  font-size: 14px;
  padding-left: calc(2.25rem / 3);
  padding-right: calc(2.25rem / 3);
  border-radius: 0.25rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  margin-top: 15px;
  margin-bottom: 15px;
}

.warning-box svg {
  color: #e51a4b;
  margin-right: 5px;
}

.shipping-address {
  color: #adb5bd;
  text-align: left;
  font-size: 14px;
  border-radius: 0.25rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.shipping-address svg {
  color: #e51a4b;
  margin-right: 5px;
}

.address-sub-title,
.form-title-send-device {
  text-align: left;
  color: #e51a4b;
  font-size: 30px;
  margin-bottom: 30px;
}

.address-sub-title {
  font-size: 24px;
  margin-bottom: 10px;
}

.unselected-list {
  padding-left: 10px;
  display: block;
  color: #fff;
  text-align: left;
  font-size: 13px;
}

.permission-wrapper {
  display: flex;
  color: #fff;
}

.short-input {
  width: calc(100% - 12px);
}

.form-response {
  padding-top: 10px;
  text-align: left;
}

.permission {
  color: #e51a4b;
  padding-left: 5px;
  text-decoration: none;
}

.shipping-form-input {
  display: flex;
  align-items: center;
  margin-right: -10px;
}

.shipping-form-input select {
  background-color: #000;
  color: #ced4da;
}

.shipping-form-input select:focus,
.shipping-form-input select:checked {
  border-color: #e51a4b !important;
}

.device-shipping-section textarea,
.device-shipping-section input {
  background: #000 !important;
  color: #fff !important;
}

.device-shipping-section ::placeholder {
  color: #5b5b5b;
}

.device-shipping-section textarea:focus,
.device-shipping-section input:focus {
  border-color: #e51a4b !important;
}

.shipping-form-input select option {
  background-color: #000;
  color: #ced4da;
}

.permission-wrapper input {
  color: #e51a4b;
}

.mantine-InputWrapper-error,
.mantine-TextInput-error {
  text-align: left;
  padding-top: 5px;
  padding-left: 5px;
}

.repair-title {
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 6px;
}

.device-wrapper {
  background-color: #000;
  border-radius: 20px;
}

.device-body-text {
  font-size: 22px;
  font-weight: 500;
}

.device-general {
  font-size: 24px;
  color: #fff;
  padding-top: 20px;
}

.total-price-btn {
  cursor: default;
  border: 0;
  background: linear-gradient(180deg, rgb(27, 161, 31) 0%, rgb(8, 59, 5) 100%);
  width: 100%;
  border-radius: 8px;
  color: #fff;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
}

.total-price-btn svg {
  margin-left: 3px;
}

.total-price-btn span {
  font-weight: bold;
  margin-left: 3px;
}

.paper-container {
  background-color: transparent;
  border: none !important;
  box-shadow: 0 0.0625rem 0.1875rem rgba(196, 192, 192, 0.05),
    rgba(94, 93, 93, 0.05) 0 1.25rem 1.5625rem -0.3125rem,
    rgba(105, 105, 105, 0.04) 0 0.625re;
}

.textarea-device div textarea {
  height: 200px;
}

.camera-title-buttons {
  cursor: default;
  background-color: #fff;
  width: 100%;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 5px;
}

.camera-price-buttons {
  cursor: default;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  width: 100px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    180deg,
    rgba(229, 26, 75, 1) 0%,
    rgba(71, 0, 74, 1) 100%
  );
}

@media only screen and (max-width: 4000px) {
  .device-body {
    justify-content: space-around;
  }
  .textarea-device {
    margin-top: 280px;
  }
  .address-information {
    padding-left: 65px !important;
  }
}

@media only screen and (max-width: 1200px) {
  .device-body {
    justify-content: space-around;
  }
  .textarea-device {
    margin-top: 410px;
  }
}

@media only screen and (max-width: 768px) {
  .address-information {
    padding-left: 10px !important;
  }
}

@media only screen and (max-width: 576px) {
  .device-con.container {
    max-width: 100% !important;
  }
  .device-general {
    font-size: 20px;
  }
}

@media only screen and (max-width: 390px) {
  .textarea-device {
    margin-top: 445px;
  }
}
