.offer-area {
  background-color: #000;
  padding-top: 50px;
  position: relative;
  z-index: 1;
  width: 100%;
  overflow: hidden;
}

@media (max-width: 1110px) {
  .offer-area {
    display: none;
  }
}

.offer-tab-link {
  text-align: center;
}
.offer-tab-link ul li {
  display: inline-block;
  padding: 10px 0;
  margin: 0 -3px;
}
.offer-tab-link ul li a {
  display: block;
  color: #fff;
  font-size: 20px;
  padding: 10px 60px;
  border-bottom: 4px solid #fff;
  font-weight: 500;
}
.offer-tab-link ul {
  list-style: none;
  padding: 0;
  margin: 0;
  border: 0;
  justify-content: center;
}
.offer-tab-link ul li a.active {
  color: darkcyan;
  border-color: darkcyan;
}
.offer-content p {
  text-align: left;
  margin: 0;
  width: 100%;
}
.offer-content h2 {
  text-align: left;
}

.offer-img {
  height: auto;
  width: 100%;
  max-height: 400px;
  object-fit: contain;
  display: flex !important;
}

.service-id {
  font-size: 40px;
  color: #4d4d4d;
}

.offerItem {
  padding: 0 15px;
  width: 80px;
  align-items: center;
  height: 544px;
  overflow: hidden;
  float: left;
  position: relative;
  transition: 0.5s ease;
}

.offerItem .offer-detail {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  padding-top: 100px;
  width: 100%;
  opacity: 0;
}

.offerItem.active .offer-detail {
  padding-top: 0;
  opacity: 1;
  transition: 1.5s opacity;
  animation: fadeIn 1.5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.offerItemTitle {
  position: absolute;
  left: 40px;
  z-index: 1;
  width: 545px;
  color: #fff;
  transform: rotate(-90deg);
  transform-origin: left;
  text-align: center;
  margin: 0 auto;
  top: 90%;
  height: 80px;
  font-size: 24px;
  line-height: 80px;
  background: #000;
  cursor: pointer;
  border-top: #282828 1px solid;
  border-bottom: #282828 1px solid;
}
.offerItem.active {
  width: calc(100% - 6 * 80px);
}

.offerItem.active .offer-detail .row .col:first-child {
  max-width: 80% !important;
}
.offer-content {
  max-width: 460px;
  margin: 0 auto;
  top: 50%;
  position: relative;
  transform: translate(0, -50%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.offerslide:after {
  content: "";
  clear: both;
  display: block;
}
.offer-content p {
  color: #fff;
}
.offer-content h2 {
  font-size: 24px;
  color: #fff;
  text-transform: uppercase;
}
.offerItemTitle.hide {
  display: none;
}
.offerItem.active .offer-content {
  transition: 0.3s ease;
  opacity: 1;
}
.offer-content a {
  text-decoration: none;
  background: linear-gradient(180deg, #a4a2a3 0%, #fff 100%);
  color: #121212;
  font-weight: bold;
  padding: 10px 20px;
  display: inline-block;
  margin-top: 20px;
  border: 0;
  border-radius: 3px;
}
/*end*/

/*media area*/
@media (max-width: 1750px) {
  .container {
    max-width: 1540px;
  }
}

@media (max-width: 1550px) {
  .container {
    max-width: 1340px;
  }
}

@media (max-width: 1280px) {
  .container {
    max-width: 1140px;
  }
  .offerItem.active .offer-detail {
    width: 750px;
  }
  .offer-detail {
    width: 595px;
  }
}

@media (max-width: 1199px) {
  .container {
    max-width: 960px;
  }
  h4.offerItemTitle {
    height: 50px;
    line-height: 50px;
    left: 25px;
    top: 94%;
  }
  .offerItem {
    width: 50px;
  }
  .offerItem.active {
    width: calc(100% - 6 * 50px);
  }
  .offerItem.active .offer-content {
    margin-left: 20px;
  }
}

@media (max-width: 991px) {
  .container {
    max-width: 720px;
  }
  .offer-content p {
    font-size: 14px;
  }
  .offerItem.active .offer-content {
    margin-left: 0;
  }
  h4.offerItemTitle {
    font-size: 14px;
    top: 93%;
    width: 280px;
    font-family: "ProximaNovaA-Regular";
    height: 36px;
    line-height: 36px;
    left: 17px;
  }
  .offerItem.active .offer-detail {
    width: 500px;
  }
  .offer-content h2 {
    font-size: 18px;
    margin: 0;
  }
  .offer-content a {
    padding: 7px 13px;
    font-size: 16px;
    margin-top: 10px;
  }
  .offer-detail {
    width: 395px;
    max-height: 279px;
  }
  .offerItem {
    width: 35px;
  }
  .offerItem.active {
    width: calc(100% - 6 * 35px);
  }
}