.fault-button {
  background: linear-gradient(
    90deg,
    rgba(164, 162, 163, 1) 0%,
    rgba(245, 244, 245, 1) 55%
  );
}

.search-box-icon {
  display: inline-flex;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  height: 40px;
  width: 30px;
  padding: 10px;
  background-color: #bebebe;
}

.search-box {
  display: inline-block;
  background-color: #bebebe;
  border: solid 1px #bebebe;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  width: 250px;
  max-width: 100%;
  font-size: 12px;
  height: 40px;
  color: #6e6e6e;
  font-weight: bold;
  outline: none;
}

.col-fix img {
  width: 100% !important;
  filter: grayscale(1);
  transition: 0.3s;
  transform: scale(0.8);
}

.col-fix {
  padding: 0;
  width: calc(25% - 20px);
}

.col-fix:hover .care-img img {
  filter: grayscale(0);
  transform: scale(0.9);
}

.search-input input {
  color: #6e6e6e !important;
  border-color: none;
}

@media only screen and (max-width: 1200px) {
  .care-img {
    width: 100% !important;
  }

  .fault-button {
    width: 100%;
  }
}

@media only screen and (max-width: 1024px) {
  .repair-body {
    display: block !important;
  }

  .repair-text {
    text-align: center;
  }

  .brand-selection {
    text-align: center !important;
  }

  .search-input {
    width: 100% !important;
    height: 40px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .search-input input {
    width: 560px;
  }
}

@media only screen and (max-width: 640px) {
  .search-input input {
    width: 400px;
  }
}

@media only screen and (max-width: 550px) {
  .search-input input {
    width: 350px;
  }
}
