.categories-container{
    background-color: black;
}

.shadowContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    width: 100%;
    max-width: 100%;
}

.categories-item{
    background-color: #ed1651;
    position: relative;
    overflow: hidden;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
    transition: flex-grow 0.3s ease-in-out;
}

.categories-item:hover{
    flex-grow: 1.5;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
    transition: flex-grow 0.3s ease-in-out;
}

.multiply{
    position: relative;
    overflow: hidden;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
    transition: flex-grow 0.3s ease-in-out;
}

.categories-text{
    position: relative;
    z-index: 99999;
}


.multiply:hover{
    opacity: 1;
    display: flex;
    position: absolute;
    z-index: 3;
    background-color: #ed1651;
    mix-blend-mode: multiply;
    width: 100%;
    height: 100%;
    transition: all ease-in 0.3s;
}

.categories-img{
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
}

.shadowContainer{
    position: relative;
    overflow: hidden;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    height: 400px;
    transition: flex-grow 0.3s ease-in-out;
}

.shadowContainer:hover{
    flex-grow: 1.5;
    cursor: pointer;
}

.categories-item:hover{
    flex-grow: 1.5;
    cursor: pointer;
}

.categories-title {
    color: white;
    font-size: 24px;
    z-index: 9999999;
    top: -157px;
    margin-left: 257px;
    position: relative;
}

.categories-title:hover {
    color: white;
    font-size: 24px;
    z-index: 9999999;
    top: -157px;
    margin-left: 257px;
    position: relative;
}

.categories-title-body{
    transition: all ease-in-out 0.4s; 
    color: white;
    font-size: 24px;
    z-index: 9999999;
    top: -169px;
    margin-left: 257px;
    position: relative;
}

.categories-title-body:hover{
    display: block!important;
    color: white;
    font-size: 24px;
    z-index: 9999999;
    top: -169px!important;
    margin-left: 257px;
    position: relative;
}

@media only screen and (max-width: 1110px) {
    .categories-container{
        display: none;
    }
}
