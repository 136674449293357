.login-section{
    padding-top: 100px;
    padding-bottom: 100px;
    background: linear-gradient(90deg, rgba(229,26,75,1) 0%, rgba(18,18,18,1) 55%);
}

.login-btn{
    background: linear-gradient( 180deg, rgba(229, 26, 75, 1) 0%, rgba(71, 0, 74, 1) 100% );
    border: 0;
}

.icon-register{
    margin-left: 5px;
}

.register-btn{
    margin-top: 10px;
    color: #fff;
    border: 0;
    width: 100%;
    background: linear-gradient(180deg, rgba(26,229,33,1) 0%, rgb(21, 124, 21) 100%);
}
.login-img{
    display: block;
}

@media only screen and (max-width: 768px) {
    .login-body{
        display: block;
    }
    .login-img{
        display: n;
        max-width: 100%;
    }
}