.fixWheelContainer{
background-color: black;
}

.fixWheelContainer {
    display: flex;
    width: 100%;
    height: 1000px;
    position: relative;
    overflow: hidden;
    align-items: center;
    justify-content: center;
}

.ecosystem-text{
    text-align: center;
    color: #e51a4b;
    text-transform: uppercase;
}

.shadowRight{
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 8;
    background: linear-gradient( 90deg, rgba(18, 18, 18, 1) 7%, rgba(18, 18, 18, 0) 50%, rgba(18, 18, 18, 1) 93% );
}

.shadowRight:focus-visible {
    outline: -webkit-focus-ring-color auto 1px;
}

.shadowTop {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 8;
    background: linear-gradient( 0deg, rgba(18, 18, 18, 1) 7%, rgba(18, 18, 18, 0) 50%, rgba(18, 18, 18, 1) 93% );
}

.shadowRight, .shadowTop {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 8;
}

.spinnerOut {
    display: flex;
    position: absolute;
    transform-origin: center center!important;
}

.spinnerOut img:hover{
    transform: rotate(168.586deg);
    transform-origin: center center;
}

.spinnerInner {
    display: flex;
    position: absolute;
    transform: rotate(-219.949deg);
    transform-origin: center center;
    -webkit-animation: rotateSquare3 3s infinite;

}

.spinnerInner:hover {
    transform: rotate(-219.949deg);
    transform-origin: center center;
}

.spinnerInner img:hover{
    transform: rotate(168.586deg);
    transform-origin: center center;
    transform:rotate(45deg);
    -ms-transform:rotate(45deg);
    -webkit-transform:rotate(45deg);
}

.spinnerLogo{
    position: absolute;
    z-index: 9;
}




/* .spinnerInner img{
    transform: rotate(-188.586deg);
    transform-origin: center center;
}

.spinnerInner:hover{
    transform: rotate(-188.586deg);
    transform-origin: center center;
}

.spinnerOut{
    transform: rotate(-188.586deg);
    transform-origin: center center;
}

.spinnerInner:hover{
    transform: translate(50px,100px);
    -webkit-transform: translate(50px,100px);
} */