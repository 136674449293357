.news-slider{
    background: #121212 !important;
    padding-top: 100px;
}

.news1-container{
    height: 544px;
    border-radius: 12px;
    margin: 0 8px;
    background-color: #1E1E1E;
}

.news1-container img{
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
}

.slider-btn{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 20px;
    margin-right: 34px;

}

.slider-btn button{
    background-color: #1E1E1E;
}

.slider-btn button:hover{
    background-color: #1E1E1E;
}

.right-icon{
    padding: 0!important;
}

.slick-arrow.slick-prev{
    background-color: #9F0F36;
}

.slick-next, .slick-prev {
    font-size: 0;
    line-height: 0;
    top: 50%;
    width: 20px;
    height: 60px;
    -webkit-transform: translate(0,-50%);
    -ms-transform: translate(0,-50%);
    transform: translate(0,-50%);
    cursor: pointer;
    color: #9F0F36;
    background-color: transparent !important;
    border-radius: 10px;
    outline: 0;
}


element.style {
    display: block;
}


.slick-next:focus, .slick-next:hover, .slick-prev:focus, .slick-prev:hover {
    color: #9F4A00;
    outline: 0;
}
.slick-prev:before {
    font-size: 15px;
    color: #ED1651;
    background-color: transparent;
    font-weight: 800;
  }

  .slick-next:before {
    font-size: 15px;
    color: #ED1651;
    background-color: transparent;
    font-weight: 800;
  }

  @media only screen and (max-width: 1110px) {
    .news-slider{
        display: none;
    }
  }