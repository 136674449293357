.buyback-container {
  background-color: #121212;
  padding-top: 100px;
}

.buyback-grid {
  margin: 0;
  padding-top: 85px;
}

.delta-btn {
  display: flex;
  font-size: 18px;
}

.delta-btn a {
  color: #fff;
  text-decoration: none;
  transform: 0.3s;
}

.device-container-btn button:hover {
  background-color: #121212;
}

.device-container {
  background: linear-gradient(212deg, rgba(246, 143, 22, 1) 0%, #ba2028 80%);
  border-radius: 12px;
  height: 320px;
}

.device-container-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 25px;
}

.device-container-btn button {
  height: 44px;
}

.payment-body {
  margin-top: 16px;
  background-color: #232323;
  border-radius: 12px;
  height: 400px;
}

.cargo-body {
  display: flex;
  align-items: center;
  text-align: center;
  background-color: #232323;
  border-radius: 12px;
  height: 320px;
  width: 100%;
  object-fit: contain;
  justify-content: center;
}

.free-cargo {
  background: linear-gradient(212deg, rgb(80, 205, 255) 0%, #722fdd 80%);
}
.fast-cash-back {
  background: linear-gradient(212deg, rgb(3, 255, 99) 0%, #085afe 80%);
}

.cargo-body img {
  display: inline-block;
  filter: brightness(99);
  padding-top: 33px;
  width: 40% !important;
  object-fit: cover;
}

.payment-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  gap: 15px;
}

@media screen and (max-width: 1110px) {
  .payment-container {
    height: auto;
  }
}

.pc-container {
  margin-top: 16px;
  background-color: #232323;
  border-radius: 12px;
}

.pc-container img {
  width: 100%;
  height: 400px;
  object-fit: contain;
  padding: 40px 5px;
}

@media only screen and (max-width: 1920px) {
  .pc-container img {
    padding-top: 0;
  }
}

@media only screen and (max-width: 1110px) {
  .buyback-title {
    font-size: 38px;
  }

  .buyback-text {
    font-size: 10px;
  }

  .buyback-grid {
    display: block;
  }

  .pc-container {
    display: none;
  }

  .buyback-section {
    max-width: 100%;
  }

  .payment-section {
    max-width: 100%;
  }

  .buyback-container {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media screen and (min-width: 640x) and (max-width: 3000px) {
  .buyback-container {
    padding-left: 100px;
    padding-right: 100px;
  }

  .pc-container img {
    width: 100%;
    padding-top: 0px;
  }
}
@media only screen and (max-width: 568px) {
  .device-container {
    background: linear-gradient(212deg, rgba(246, 143, 22, 1) 0%, #ba2028 80%);
    border-radius: 12px;
    height: 425px;
  }
}
