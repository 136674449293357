footer{
    background-color: black;
    padding-left: 130px;
    padding-right: 130px;
    padding-top: 150px;
    padding-bottom: 20px;
}


.footer-container{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-top: 28px;
}

.location-body{
    display: flex;
    align-items: flex-start;
}


.location-body img{
    padding-top: 8px;
}

.footer-div{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer-menu{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 50px;
    padding-bottom: 32px;
    margin-bottom: 2.1875rem;
}

.location-body, .mail, .call, a div{
    text-decoration: none;
    transition: 0.3s;
    color: #fff;
}

.social-list{
    display: flex;
    column-gap: 28px;
    justify-content: center;
}

.hover-div{
    color: #fff;
    transition: 0.3s;

}

.hover-div:hover{
    color: #ED1651;
}


.hover-icon{
    color: #fff;
    transition: 0.3s;
}

.hover-icon:hover{
    color: #ED1651;
}

.mail{
    display: flex;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 25px;
}
.call{
    display: flex;
    align-items: center;
}

.mail-text,
.call-text{
    margin-left: 8px;
}

.footer-container img {
    width: 32px;
    height: 32px;
}

@media only screen and (max-width: 1370px){
    footer{
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 50px;
    }

    .footer-menu{
        display: none;
    }

    .mail{
        padding-top: 0px;
    }

    .socia-icons{
        text-align: end;
    }
   
}

@media only screen and (max-width: 768px){
    .social-text{
        display: none;
    }

    .footer-container {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        padding-top: 28px;
    }

    .location-body {
        display: flex;
        width: 100%;
        align-items: flex-start;
        flex-wrap: nowrap;
        justify-content: center;
        align-content: flex-start;
        margin-top: 20px;
    }

    .footer-div {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;

    }
    
    .call {
        display: flex;
        padding-bottom: 30px;
    }

    .footer-logo{
        text-align: center;
    }

}