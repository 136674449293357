.service-section{
    padding-left: 35px;
    padding-right: 35px;
    background: linear-gradient( 180deg, #53091a 0%, #121212 5% );
    background-color: #121212;
    border-top: solid 1px #E51A4B;
}

.service-btn {
    border-radius: 12px;
    background: linear-gradient( 180deg, rgba(229, 26, 75, 1) 0%, rgba(71, 0, 74, 1) 100% );
    border: 0;
}

@media screen and (max-width: 992px) {

.servicebody{
    display: block;
}

.warranty-reliable{
    padding-top: 20px;
    font-size: 22px;
    text-align: center;
}

.professional-solution{
    font-size: 22px;
}

.service-img-body{
    width: 100%;
}

.professional-solution{
    font-size: 22px;
    padding-bottom: 20px;
    text-align: center;
}

.service-div,
.service-div-col{
    width: 100%;
}

.service-div-img{
    padding-top: 75px;
}

.service-general-body{
    padding-bottom: 10px!important;

}

.service-div-text{
    font-size: 22px;
}
}