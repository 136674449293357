.register-section {
  padding-bottom: 100px;
  background: linear-gradient(
    90deg,
    rgba(229, 26, 75, 1) 0%,
    rgba(18, 18, 18, 1) 55%
  );
}

.register-form-wrapper {
  padding-top: 20px;
}

.login-img {
  padding-top: 120px;
}

.form-response {
  color: #e51a4b;
}

.login-btn {
  background: linear-gradient(
    180deg,
    rgba(229, 26, 75, 1) 0%,
    rgba(71, 0, 74, 1) 100%
  );
  border: 0;
}

.required {
  color: #e51a4b;
  margin-left: 5px;
}

.buttons-wrapper{
  margin-top: 20px;
  justify-items: end;
  margin-right: 12px;
}

.register-buton {
  border: 0;
  background: linear-gradient(
    180deg,
    rgba(26, 229, 33, 1) 0%,
    rgb(21, 124, 21) 100%
  );
}

.already-register-buton a{
  text-decoration: none;
  color: #fff;
}

.already-register-buton {
  border: 0s;
  background: linear-gradient(
    180deg,
    rgba(229, 26, 75, 1) 0%,
    rgba(71, 0, 74, 1) 100%
  );
}

.special-label {
  display: none;
}

.register-form {
  margin-bottom: 40px;
  size: 420px;
  margin: auto;
  text-align: left;
}

.phone-input input:focus {
  box-shadow: none !important;
}

.phone-input {
  width: 100%;
}
@media only screen and (max-width: 768px) {
  .register-body {
    display: block;
  }
}
