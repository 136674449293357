.information-container {
  background-color: #121212;
  padding-bottom: 100px;
}

.screen-container {
  background: linear-gradient(
    212deg,
    rgba(246, 143, 22, 1) 0%,
    rgba(186, 32, 40, 1) 100%
  );
  height: 350px;
  border-radius: 50px;
  display: flex;
  align-items: inherit;
  overflow: hidden;
}

.screen-img {
  padding-right: 102px;
}

.screen-img img {
  height: 100%;
}

.gradient-contanier {
  background: -webkit-linear-gradient(283deg, #5e55ff, #ff0000 80%);
  height: 100%;
  border-radius: 50px;
}

.custom-area-title {
  line-height: 1.1;
  margin-bottom: 20px;
}

.orijinal-container {
  overflow: hidden;
  background: linear-gradient(0deg, #343434 0%, #1c1c1c 100%);
  border-radius: 50px;
  height: 350px;
}

.sss-container {
  background-color: #171350;
  height: 350px;
  border-radius: 50px;
  display: flex;
  gap: 20;
}

.sss-img {
  padding-top: 35px;
}

.orijinal-text {
  line-height: 50px;
}

.categoriesText {
  display: flex;
  position: absolute;
  z-index: 6;
  opacity: 0;
  color: white;
  font-size: 24px;
  font-weight: 3 00;
  transition: all ease-in-out 0.4s;
}

.screen-section {
  margin-bottom: 18px;
  margin-top: 100px;
}

@media only screen and (max-width: 568px) {
  .information-container {
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen and (max-width: 110px) {
  .information-container {
    display: none;
  }
}
