.servicefee-section {
  margin: 0;
  max-width: 100%;
  background-color: #202020;
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.device-img-about {
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}

.change-model-div {
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}

.device-img-about,
.change-model-div {
  background-color: #1a1a1a;
  padding: 5px;
}

.change-model-div button {
  border: 0;
  transition: 0.3s;
  background: #000;
}

.change-model-div button:hover,
.change-model-div button:active {
  border: 0;
  background: linear-gradient(
    180deg,
    rgba(229, 26, 75, 1) 0%,
    rgba(71, 0, 74, 1) 100%
  );
}

.change-model-div svg {
  width: 13px;
  height: 13px;
  margin-right: 10px;
}

.device-img-about img {
  max-height: 527px;
  object-fit: contain;
}

.servicefee-img svg {
  width: 100%;
  object-fit: contain;
}

.camera-button {
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(229, 26, 75, 1) 0%,
    rgba(71, 0, 74, 1) 100%
  );
}

.camera-buttons {
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(229, 26, 75, 1) 0%,
    rgba(71, 0, 74, 1) 100%
  );
}

.repair-list {
  padding: 25px;
  border-radius: 15px;
  background-color: #1a1a1a;
}

.buyback-information {
  background-color: #343434;
  border-radius: "12px";
}

.service-repair-title {
  padding-top: 40px;
  padding-bottom: 20px;
  font-size: 15px;
  margin-bottom: 25px;
  color: #fff;
  text-align: left;
}

.change-model {
  border: 1px solid #707070;
  background-color: #000;
  margin-top: 17px;
  margin-bottom: 15px;
  border-radius: 12px;
}

.card {
  padding: 10px;
  width: 100%;
  position: relative;
  background-color: #343434;
}

.card-info-wrapper {
  color: #fff;
  background: linear-gradient(
    180deg,
    rgba(229, 26, 75, 1) 0%,
    rgba(94, 11, 31, 0.9838060224089635) 100%
  );
  position: absolute;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 10px;
  top: 1px;
  right: 0;
  width: 30px;
  height: 30px;
  align-items: center;
  display: flex;
  transition: 0.5s;
}

.card-info-desc {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  color: #343434;
  background-color: #f9f9f9;
  padding: 5px;
  border-radius: 5px;
  border: 1px dotted rgb(107, 0, 0);
  transform: translateY(-10px);
  opacity: 0;
  z-index: 1;
  margin-top: 5px;
  max-width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-info {
  width: 100%;
  text-align: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.card-info-wrapper:hover .card-info-desc {
  font-size: 12px;
  display: block;
  opacity: 1;
  transform: translateY(0);
}

.card-image {
  margin: auto;
}

.card-image img {
  height: auto;
  width: 100%;
  max-height: 250px;
  object-fit: contain;
}

.description-area {
  color: #fff;
  padding-top: 40px;
  padding-bottom: 120px;
}

.description-area .title {
  font-size: 42px;
  margin-bottom: 20px;
}

.card-title {
  padding-top: 10px;
  color: #fff;
}

.card-price {
  color: #fff;
  font-size: 12px;
}

.passive {
  background: #343434 !important;
}

.passive span {
  white-space: normal;
  text-align: center;
  line-height: 1.5;
  text-transform: capitalize;
}

.add-to-basket {
  margin-top: 10px;
  background-color: #fff;
  border-radius: 12px;
  width: 100%;
  height: 45px;
  border: 0;
  line-height: 2.5;
  color: #fff;
  transition: 0.5s;
  background: linear-gradient(
    180deg,
    rgba(229, 26, 75, 1) 0%,
    rgba(94, 11, 31, 0.9838060224089635) 100%
  );
}

.scroll-list {
  padding-right: 4px;
  max-height: 475px;
  overflow-y: scroll;
}

.totally-area {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg-grey {
  background-color: #1a1a1a;
  border-radius: 12px;
}

.repair-list-area {
  margin-top: 20px;
  background-color: #1a1a1a;
  padding-left: 8px;
  padding-bottom: 20px;
  padding-right: 4px;
  border-radius: 15px;
}

.repair-list-area .camera-title-buttons {
  background: linear-gradient(186deg, #444444, #b4b4b4);
  font-weight: bold;
  padding-left: 8;
}

.remove-in-list {
  margin-top: 3px;
  padding-right: 3px;
  color: #474747;
  cursor: pointer;
}

.camera-title-buttons {
  text-align: left;
}

.camera-title-buttons div {
  font-size: 14px;
}

.remove-to-basket {
  margin-top: 10px;
  background-color: #fff;
  border-radius: 12px;
  width: 100%;
  height: 45px;
  border: 0;
  line-height: 2.5;
  color: #fff;
  transition: 0.5s;
  background: linear-gradient(180deg, #3fc100 0%, #083e00 100%);
}

.totally {
  display: block;
  justify-content: space-between;
  font-size: 24px;
  color: #fff;
  background: linear-gradient(180deg, #3fc100 0%, #083e00 100%);
  border-radius: 12px;
  margin-top: 20px;
  padding: 30px 20px;
  width: 100%;
}

.totally .price-text {
  color: #fff;
  font-weight: bold;
}

.row-item-fuse {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 20px;
  background: #262626;
  padding: 10px 5px;
  border-radius: 12px;
}

.total-next {
  border: 0;
  border-radius: 12px;
  height: 40px;
  margin-top: 40px;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(229, 26, 75, 1) 0%,
    rgba(71, 0, 74, 1) 100%
  );
}

.camera-title-button {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.camera-price-button {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    180deg,
    rgba(229, 26, 75, 1) 0%,
    rgba(71, 0, 74, 1) 100%
  );
}

.change-body {
  background: linear-gradient(
    90deg,
    rgba(164, 162, 163, 1) 0%,
    rgba(245, 244, 245, 1) 55%
  );
  border-radius: 20px;
  width: 240px;
  height: 40px;
}

.screen-img {
  padding: 12px;
  padding-bottom: 0px;
}

.css-toggle {
  padding: 1%;
  text-align: center;
  display: inline-block;
  /* transform: rotate(270deg);  */
  border: none !important;
}
.css-toggle:hover span {
  display: block;
}

.screen-change-body {
  transform: rotate(272deg);
  background-color: #000000;
  width: 8%;
}

section {
  padding-left: 0px !important;
  padding-right: 0px !important;
  width: 980px;
  transition: width 0.35s ease;
}

.item {
  width: 8%;
  height: 800px;
  background-color: #000000;
  float: left;
  font: 20px monospace;
  z-index: 5;
  overflow: hidden;
  padding: 10px;
  cursor: pointer;
  border: 1px solid #707070;
}

.screen-item-div {
  width: 10%;
  height: 200px;
  background-color: #000000;
  float: left;
  font: 20px monospace;
  padding: 10px;
  cursor: pointer;
}

.screen-div-text {
  transform: rotate(270deg);
  top: 50%;
  position: relative;
  display: flex;
}

.service-switch label {
  background-color: #343434;
}

.info {
  z-index: 1;
  float: left;
  overflow: hidden;
  height: 800px;
  background: #000000;
  visibility: hidden;
  border: 1px solid #707070;
  width: 0;
  transition: width 1s, padding 1s;
}

.info.active {
  padding: 15px 25px;
  visibility: visible;
  width: 59%;
}

.description-btn {
  background-color: #897489;
}
.description-btn:hover {
  background-color: #9b8a9b;
}
@media screen and (max-width: 1350px) {
  .servicefee-text {
    margin: 0;
  }

  .device-general-condition {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .repair-body {
    width: 100%;
  }

  .device-img-about {
    background-color: #1a1a1a;
    display: inline-grid !important;
    width: 100%;
    align-items: center;
  }

  .device-img-about img {
    width: 100%;
    height: 100%;
  }

  .device-title {
    font-size: 1.875rem;
  }

  .servicefee-section {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

@media screen and (max-width: 992px) {
  .screen-change,
  .battery-change,
  .microphone-change,
  .charge-change,
  .camera-pine,
  .motherboard,
  .home-change {
    width: 100% !important;
    height: 100% !important;
    padding-bottom: 7rem;
    margin-bottom: 30px;
  }
  .charge-change-body {
    display: block !important;
  }

  .repair-section {
    display: block !important;
  }
  .device-general {
    font-size: 2rem;
  }
}

@media screen and (max-width: 850px) {
  .battery-change {
    height: 100% !important;
  }
  .animation-title {
    font-size: 38px;
  }
  .animation-text {
    font-size: 20px;
    width: 100%;
  }
  .home-svg {
    width: 100%;
  }
  .animasion-body {
    display: none;
  }
}

@media screen and (max-width: 640px) {
  .device-general-condition {
    display: block;
  }
  .battery-replacement-div {
    justify-content: center;
  }
  .camera-change-div,
  .camera-change {
    justify-content: center;
  }
  .charge-change-body {
    display: block !important;
  }

  .repair-section {
    display: block !important;
  }
  .screen-change,
  .battery-change,
  .microphone-change,
  .charge-change,
  .camera-pine,
  .motherboard,
  .home-change {
    width: 100% !important;
    height: 100% !important;
    padding-bottom: 7rem;
    margin-bottom: 30px;
  }
  .repair-div {
    margin: auto !important;
  }
  .repair-body {
    margin-top: 70px;
  }
  .buyback-information {
    margin: auto;
    width: 100% !important;
  }
  .buyback-information div {
    width: 100% !important;
  }
  .servicefee-text {
    font-size: 32px;
    padding-top: 25px;
    padding-bottom: 10px;
    text-align: left;
  }
  .service-div-text {
    font-size: 16px;
    padding-top: 20px;
  }
  .fee-information,
  .fee-information-div {
    font-size: 1rem;
  }
}
