.banner-container {
  background-color: black;
}

.video {
  width: 100%;
  height: calc(100vh - 85px);
  overflow: hidden;
  transition: all 1s ease-out;
}

.banner-col {
  padding-left: 10%;
  position: absolute;
}

.banner-title-container {
  z-index: 2;
}

.banner-title {
  font-size: 80px;
  color: white;
  font-weight: bold;
}

.banner-row {
  margin-right: 0;
}

.video {
  overflow: hidden;
  width: 100%;
  right: 0px;
  display: flex;
  align-content: center;
  transition: all 1s ease-out;
  animation: width-a359bdf0 4.5s ease-out;
}

.fullscreen-video {
  animation: translate-a359bdf0 4.5s linear;
  filter: brightness(0.3);
  max-height: calc(100vh - 85px);
  width: 100%;
  object-fit: cover;
}

@media screen and (min-width: 1525px) {
  .fullscreen-video {
    width: 100% !important;
    height: auto !important;
  }
}

.banner-text {
  color: #fff;
  width: 300px;
  line-height: 1.25;
  position: relative;
  z-index: 2;
  font-weight: 700 !important;
  font-size: 6rem;
}

.video-section {
  padding-right: 0px !important;
}

@media only screen and (max-width: 768px) {
  .banner-text {
    font-size: 70px;
  }
}

@media only screen and (max-width: 568px) {
  .banner-text {
    font-size: 40px;
  }
}

@media only screen and (max-width: 470px) {
  .watch-img {
    width: 300px;
    height: 100%;
    text-align: center;
    margin: auto;
    padding-top: 120px;
  }
}
