.about-container {
  background-color: #121212;
  padding-bottom: 90px;
}

.economy-body {
  padding-left: 30px;
}

.text-box-title {
  color: #ed1651;
  padding-top: 19%;
  padding-left: 0.5rem;
  font-size: 72px;
  font-weight: 500;
  text-align: left;
}

.text-box-pl-0-5 {
  font-size: 15px;
  color: #d1d1d1;
  padding-left: 10px;
  text-align: left;
}

.guaranteed-container {
  margin-top: 20px;
  background: linear-gradient(180deg, #1f263e 0%, #273b7d 100%);
  border-radius: 35px;
  display: flex;
  height: 300px;
  max-height: 420px;
  overflow: hidden;
}

.team-container {
  height: 620px;
  overflow: hidden;
  background: linear-gradient(180deg, #164665 0%, #ed1651 100%);
  border-radius: 35px;
}

.economy-container-section {
  height: 620px;
}

.economy-container {
  height: 300px;
  max-height: 420px;
  overflow: hidden;
  border-radius: 12px;
  background: linear-gradient(180deg, #343434 0%, #171717 100%);
}

.economy-img {
  width: 200%;
}

.watch-img {
  object-fit: cover;
  padding-top: 100px;
}

.watch-img img {
  width: 150%;
  margin-bottom: -25px;
  margin-left: -200px;
  padding-right: 20px;
}

.service-container {
  height: 620px;
  max-height: 850px;
  background: linear-gradient(180deg, #330928 0%, #765a6f 100%);
  border-radius: 35px;
  padding-top: 48px;
}

.economy-body-img {
  position: relative;
  overflow: hidden;
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: flex-end;
}

.economy-body-img img {
  height: 100%;
  position: relative;
}

.scrooter-img {
  overflow: hidden;
}

.scrooter-img img {
  width: 170%;
  filter: drop-shadow(6mm -3mm 3mm #0000009e);
}

.vacum-body-img {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.vacum-body-img img {
  object-fit: contain;
  width: 350px;
  margin-left: -150px;
}

@media (max-width: 1399px) {
  .service-container,
  .economy-container-section,
  .team-container {
    height: 540px !important;
  }

  .guaranteed-container,
  .economy-container {
    height: 260px;
  }
}

@media (max-width: 992px) {
  .border-radius-sm-1 {
    border-radius: 5px !important;
  }
  .mt-sm-custom-1 {
    margin-top: 15px !important;
  }

  .scrooter-img img {
    width: 130% !important;
  }

  .blog-container {
    padding: 8px;
  }
}

@media only screen and (max-width: 1399px) {
  .service-container {
    background: linear-gradient(180deg, #330928 0%, #765a6f 100%);
    border-radius: 35px;
    padding-top: 48px;
    margin: auto !important;
  }
}

@media only screen and (max-width: 993px) {
  .vacum-body-img img {
    object-fit: contain;
    height: 100%;
    width: 200%;
    background-repeat: repeat-x;
  }

  .watch-img {
    width: 100%;
    height: 100%;
    text-align: center;
    margin: auto;
    padding-top: 50px;
  }
}

@media only screen and (max-width: 992px) {
  .watch-img {
    height: 100%;
    text-align: center;
    margin: auto;
    padding-top: 30px;
  }
}

@media (max-width: 992px) {
  .economy-img {
    width: 100% !important;
  }
  .vacum-body-img img {
    height: auto;
    width: 100%;
  }
  .watch-img img {
    width: 100%;
    margin: auto;
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media only screen and (max-width: 568px) {
  .about-title {
    font-size: 3.375rem;
  }
}

@media only screen and (max-width: 500px) {
  .watch-img {
    height: 100%;
    text-align: center;
    margin: auto;
    padding-top: 119px;
  }
}
