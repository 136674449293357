.search-input {
  margin-top: 10px;
  background-color: #fff;
  border-radius: 12px;
  width: 240px;
  height: 40px;
  margin-right: 20px;
}
.table {
  border: solid 1px #e51a4b;
}

.white-space-none {
  white-space: none;
}

.show-phone {
  text-decoration: none;
  color: #fff;
}
.ReactModalPortal div {
  z-index: 10;
  background-color: rgb(0 0 0 / 75%) !important;
}

.ReactModalPortal button {
  background: #000;
  color: #fff;
}

.close-button {
  width: 100%;
  text-align: right;
  margin-bottom: 10px;
}

.show-images {
  cursor: pointer;
  transition: 0.3s;
  display: block;
  background: transparent;
  padding-left: 0;
}

.show-images:hover {
  color: #fff !important;
}

.modal-device-images {
  width: 200px;
  aspect-ratio: 1 / 1;
  padding: 10px;
  transition: 0.3s;
}

.ReactModal__Content {
  max-height: 80vh;
}
